import React from "react"
import Layout from "../components/Layout"
import PostCard from "../components/elements/PostCard"
import { graphql } from "gatsby"

const IndexPage = (props) => {
  const { posts } = props.data.allContentfulPost
  return (
    <Layout path={props.path} header="Recent Posts" title="Recent Posts">
      {posts.map(({
        title, slug, subtitle, createdAt, coverImage
      }, index) => <PostCard key={slug + index} header={title} linkTo={`/posts/${slug}`} subHeader={subtitle} dateString={createdAt} coverImage={coverImage} />
      )}

    </Layout>
  )
}

export const recentQuery = graphql`
  query AllPostsQuery {
    allContentfulPost(sort: {order: DESC, fields: createdAt}) {
      posts: nodes {
        body {
          body
        }
        childContentfulPostBodyRichTextNode {
          json
        }
        subtitle
        title
        slug
        createdAt
        tags
        coverImage {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default IndexPage
